import React from "react";

import "./App.css";
import "./index.css";
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <div class={"header"}>
            <h1>Jatan Loya</h1>
          </div>
          <br />
          
            <div className="Portfolio">
              <h1>Portfolio</h1>
            </div>
          
          <hr></hr>
          <br />
          <h2>About</h2>

          <div class="about">
            <h4>
            A dedicated computer science engineering student with a passion for cyber and information security. Interested in learning and implementing cutting edge security practices. High ability for problem solving and learning. Always striving to learn new things.
            </h4>
          </div>
          
<div className="projects">
          <h2>Projects</h2>

          <h3>1. Keystroke Analysis for Authentication</h3>
          <h4>
            Developing a system for analysing
            keystroke to authenticate
             users based on inherent typing behaviour.
          </h4>
          <a
            className="App-link"
            href="https://github.com/jatanloya/KeystrokeAnalysis"
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub Repo
          </a>
          <h3>2. Password Manager -- A React Web App</h3>
          <h4>
            Developed a password manager using ReactJS
            and implemented various hash functions like
            SHA-256, Bcrypt, PBKDF2.
          </h4>

          <h3>3. Chat Bot -- A DialogFlow Implementation</h3>
          <h4>
            Developed a chat bot using the DialogFlow framework,
            with custom intents, entities and various integrations
            like Slack and Telegram.
          </h4>
          <a
            className="App-link"
            href="https://bot.dialogflow.com/e1f65cc7-a0f4-442d-840e-9d067e79f499"
            target="_blank"
            rel="noopener noreferrer"
          >
            Web Demo
          </a>
          </div>
          
        </p>

        <a
          className="App-link"
          href="https://drive.google.com/open?id=1l8ZqoKs5lh-07Fv5yrmREypY-0Srbk7LtbLjKPDqDS8"
          target="_blank"
          rel="noopener noreferrer"
        >
          Resume (Google Drive)
        </a>
        <a
          className="App-link"
          href="https://www.linkedin.com/in/jatan-loya-a796b868"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
        <a
          className="App-link"
          href="https://www.github.com/jatanloya"
          target="_blank"
          rel="noopener noreferrer"
        >
          GitHub
        </a>
        <a
          className="App-link"
          href="https://www.leetcode.com/jatanloya"
          target="_blank"
          rel="noopener noreferrer"
        >
          Leetcode
        </a>
        <a
          className="App-link"
          href="https://www.hackerrank.com/jatanloya"
          target="_blank"
          rel="noopener noreferrer"
        >
          HackerRank
        </a>
        <br />

        <h6>
            This is an attempt to make a static
            <br /> and lightweight portfolio site with ReactJS.
            <br /> Work in progress.
          </h6>
      </header>
    </div>
  );
}
export default App;
